//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as html2pdf from 'html2pdf.js';

import ExportEventsHelpStep from '@/components/Together/ExportEventsHelpStep.vue';
import ExportEventsImageArea from '@/components/Together/ExportEventsImageArea.vue';
import { TimeHelper, capitalizeFirstLetter } from '@/services/helpers.js';
import moment from 'moment-timezone';

export default {
  name: 'ExportEventsModalComponent',
  props: {
    selected: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showExportEventsModal: true,
      currentTab: 'whatsapp', // current selected tab
      oneEvent: this.selected[0], // one event selected
      multipleEvents: this.selected, // multiple events selected
      showCopyWarning: false, //  show div if copy failed
      showCopySuccess: false, // show div when copy was successful
      selectedImage: 0, //  position of selected image for copy
      showUserInput: false, // show editable div for user input
      stringReplacement: 'qkx2q14', //  string replacement to make \n work and fix the bug Safari does not copy <br /> tag properly
      selectedImagePdf: this.$t('events.export_events_modal.no_header'), // selected image pdf header
      options: [
        this.$t('events.export_events_modal.no_header'),
        'Dzamling Gar',
        'Kunsangar North',
        'Kunsangar South',
        'Merigar East',
        'Merigar West',
        'Namgyalgar',
        'Tashigar Norte',
        'Tashigar Sur',
        'Tsegyalgar East',
        'Tsegyalgar West',
      ],
    };
  },
  components: {
    ExportEventsImageArea,
    ExportEventsHelpStep,
  },
  methods: {
    createPdfMultipleEvents() {
      const element = document.getElementById('multipleCopyPdf');
      html2pdf(element, {
        margin: [12, 40],
        pagebreak: { avoid: '.no-split' },
        filename: 'SanghaWeb_multiple_events.pdf',
        html2canvas: {
          dpi: 300,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      });

      this.showExportEventsModal = false;

      // This comment function is the backup plan. For cases when html2pdf will not more be available.
      // This is a solution with a classic browser popup print window.

      /* let mywindow = window.open(
        '',
        '_blank',
        'PRINT',
        'height=auto,width=auto'
      );
      mywindow.document.write('<html>');
      mywindow.document.write('<head>');
      mywindow.document.write('</head>');
      mywindow.document.write('<body>');

      mywindow.document.write(
        document.getElementById('multipleCopyPdf').innerHTML
      );
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10

      mywindow.print();
      mywindow.close();

      return true; */
    },
    createPdfOneEvent() {
      const element = document.getElementById('oneEventCopyPdf');

      html2pdf(element, {
        margin: [12, 40],
        pagebreak: { avoid: '.no-split' },
        filename: 'SanghaWeb_single_event.pdf',
        html2canvas: {
          dpi: 300,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      });

      this.showExportEventsModal = false;

      // This comment function is the backup plan. For cases when html2pdf will not more be available.
      // This is a solution with a classic browser popup print window.

      /* let mywindow = window.open(
        '',
        '_blank',
        'PRINT',
        'height=auto,width=auto'
      );

      mywindow.document.write('<head>');
      mywindow.document.write('</head>');
      mywindow.document.write('<body>');

      mywindow.document.write(
        document.getElementById('oneEventCopyPdf').innerHTML
      );
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10

      mywindow.print();
      mywindow.close();

      return true; */
    },
    toggleUserInput() {
      this.showUserInput = !this.showUserInput;
      return;
    },

    /* Whatsapp copy functions */

    copyOneEventWa() {
      const copyOneEventWa = document
        .getElementById('oneEventCopyWa')
        .innerText.replaceAll('\n', '')
        .replaceAll(this.stringReplacement, '\n');

      navigator.clipboard
        .writeText(copyOneEventWa)
        .then(() => {
          this.showCopySuccess = true;
          setTimeout(() => {
            this.showCopySuccess = false;
          }, 3000);
        })
        .catch((e) => {
          e && (this.showCopyWarning = true);
        });
    },

    copyMultipleEventsWa() {
      const multipleCopyWa = document
        .getElementById('multipleCopyWa')
        .innerText.replaceAll('\n', '')
        .replaceAll(this.stringReplacement, '\n');

      navigator.clipboard
        .writeText(multipleCopyWa)
        .then(() => {
          this.showCopySuccess = true;
          setTimeout(() => {
            this.showCopySuccess = false;
          }, 3000);
        })
        .catch((e) => {
          e && (this.showCopyWarning = true);
        });
    },

    /* Facebook copy functions */

    copyOneEventFb() {
      const oneEventCopyFb = document
        .getElementById('oneEventCopyFb')
        .innerText.replaceAll('\n', '')
        .replaceAll(this.stringReplacement, '\n');

      navigator.clipboard
        .writeText(oneEventCopyFb)
        .then(() => {
          this.showCopySuccess = true;
          setTimeout(() => {
            this.showCopySuccess = false;
          }, 3000);
        })
        .catch((e) => {
          e && (this.showCopyWarning = true);
        });
    },

    copyMultipleEventsFb() {
      const multipleCopyFb = document
        .getElementById('multipleCopyFb')
        .innerText.replaceAll('\n', '')
        .replaceAll(this.stringReplacement, '\n');

      navigator.clipboard
        .writeText(multipleCopyFb)
        .then(() => {
          this.showCopySuccess = true;
          setTimeout(() => {
            this.showCopySuccess = false;
          }, 3000);
        })
        .catch((e) => {
          e && (this.showCopyWarning = true);
        });
    },

    /* Mail copy functions */

    copyOneEventMail() {
      const oneEventCopyMail = document
        .getElementById('oneEventCopyMail')
        .innerText.replaceAll('\n', '')
        .replaceAll(this.stringReplacement, '\n');

      navigator.clipboard
        .writeText(oneEventCopyMail)
        .then(() => {
          this.showCopySuccess = true;
          setTimeout(() => {
            this.showCopySuccess = false;
          }, 3000);
        })
        .catch((e) => {
          e && (this.showCopyWarning = true);
        });
    },

    copyMultipleEventsMail() {
      const multipleEventsCopyMail = document
        .getElementById('multipleCopyMail')
        .innerText.replaceAll('\n', '')
        .replaceAll(this.stringReplacement, '\n');

      navigator.clipboard
        .writeText(multipleEventsCopyMail)
        .then(() => {
          this.showCopySuccess = true;
          setTimeout(() => {
            this.showCopySuccess = false;
          }, 3000);
        })
        .catch((e) => {
          e && (this.showCopyWarning = true);
        });
    },

    openWa() {
      window.open('https://web.whatsapp.com/', '_blank', 'noopener,noreferrer');
    },

    openFb() {
      window.open('https://facebook.com/', '_blank', 'noopener,noreferrer');
    },

    openMail() {
      window.location.href = 'mailto:#';
    },

    cycleImages() {
      this.selectedImage++;
      if (this.selectedImage === this.selectedImages.length) {
        this.selectedImage = 0;
      }
    },
  },
  watch: {
    showExportEventsModal() {
      this.$emit('closeModal', null);
    },
  },
  computed: {
    getImage() {
/* 
'Dzamling Gar',
'Kunsangar North',
'Kunsangar South',
'Merigar West',
'Merigar East',
'Namgyalgar',
'Tashigar Norte',
'Tashigar Sur',
'Tsegyalgar East',
'Tsegyalgar West',
*/



      switch (this.selectedImagePdf) {
        case 'Dzamling Gar':
          return require('@/images/export-modal-images/dzamling.png');
        case 'Kunsangar North':
          return require('@/images/export-modal-images/kunNorth.png');
        case 'Kunsangar South':
          return require('@/images/export-modal-images/kunSouth.png');
        case 'Merigar West':
          return require('@/images/export-modal-images/merWest.png');
        case 'Merigar East':
          return require('@/images/export-modal-images/merEast.png');
        case 'Namgyalgar':
          return require('@/images/export-modal-images/namgyalgar.png');
        case 'Tashigar Norte':
          return require('@/images/export-modal-images/tasNorth.png');
        case 'Tashigar Sur':
          return require('@/images/export-modal-images/tasSouth.png');  
        case 'Tsegyalgar East':
          return require('@/images/export-modal-images/tseEast.png');
        case 'Tsegyalgar West':
          return require('@/images/export-modal-images/tseWest.png');
        default:
          return null;
      }
    },
    selectedImages() {
      const selectedImagesArray = [];
      this.selected.forEach((event) => selectedImagesArray.push(event.image));
      return selectedImagesArray;
    },
    //  Reorganize values in the order we can loop and show them in the correct way
    selectedOrganised() {
      // Separate values we want to show in multiple selection view

      const result = this.selected.map(
        ({ id, title, date, venue, url, password }) => ({
          id: id,
          title: title,
          date: date,
          venue: venue,
          url: url,
          password: password,
        })
      );

      // Sort separated values by date

      const sorted = [...result].sort(function (a, b) {
        if (a.date < b.date) return -1;
        if (a.date > b.date) return 1;
        return 0;
      });

      // Reorganize sorted separated values in the order we can loop and show them in the correct way

      const selectedOrganiseHelper = {};

      sorted.forEach((event) => {
        const date = new Date(event.date);
        const month = moment(date)
          .tz(TimeHelper.getDefaultTimezone())
          .format('M / YYYY')
          .toUpperCase();

        const dayNumber = moment(date)
          .tz(TimeHelper.getDefaultTimezone())
          .format('D.M. ');
        const dayWord = capitalizeFirstLetter(
          moment(date).tz(TimeHelper.getDefaultTimezone()).format('dddd')
        );
        const day = dayNumber + dayWord;

        const isMonthEmpty = !selectedOrganiseHelper[month];
        const isDayEmpty = isMonthEmpty
          ? true
          : !selectedOrganiseHelper[month][day];

        if (isDayEmpty) {
          if (isMonthEmpty) {
            selectedOrganiseHelper[month] = {};
          }
          selectedOrganiseHelper[month][day] = [event];
        } else {
          selectedOrganiseHelper[month][day].push(event);
        }
      });
      return selectedOrganiseHelper;
    },
  },
};
